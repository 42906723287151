<template>
  <el-container direction="vertical">
    <Header></Header>
    <el-main>
      <div class="content">
        <h2>请选择需要发布的数据类型</h2>
        <div>
          <el-button color="#35b260" type="success" @click="jump_page('/sportsman_detail_release')">球员数据</el-button>
          <el-button color="#35b260" type="success" @click="jump_page('/complex_release')">综合数据</el-button>
        </div>
      </div>
    </el-main>
    <Footer></Footer>
  </el-container>
</template>

<script>

import Header from "../components/Header";
import Footer from "../components/Footer";
import {jump_page} from "../tool/tools";
import {message_hint} from "../tool/tools";

export default {
  name: "PublishData",
  beforeCreate() {
    // 设置CSS样式
    document.querySelector("body").setAttribute("style", "margin: 0;padding: 0;")
  },
  methods: {
    jump_page,
    message_hint
  },
  components: {
    Header,
    Footer
  }
}
</script>

<style scoped>
@import "../static/universal/index.css";

.content{
  background: white;
  border-radius: 8px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 15px;
  text-align: center;
}
</style>